import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import { SelectButton } from 'primereact/selectbutton';
/* import {Row, Col} from  'react'; */
import { Button } from 'primereact/button';
import { Knob } from 'primereact/knob';
import { MultiSelect } from 'primereact/multiselect';



function ActivitysLogger () {
    const [value2, setValue2] = useState(null);
    const paymentOptions = [
        {name: 'Today', value: 1},
        {name: 'Yesterday', value: 2},
        {name: 'MTD', value: 3},
        {name: 'Past Month', value: 4},
        {name: 'Last 3 Month', value: 5},
        {name: 'Last 6 Month', value: 6}

    ];
    const Options = [
        {name: 'Todays Goal', value: 1},
        {name: 'Monthly Goal', value: 2},
        {name: 'Chart', value: 3},
        {name: 'Summary', value: 4},
    ];
    const categoryOptions = [
        {name: 'Premium $126,021', value: 1},
        {name: 'Policies 91', value: 2},
        {name: 'Revence $12,880', value: 3},
    ];

    const basicData = {
        labels: ['Rise B...', 'Jennif...', 'Pamel...'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [0, 100, 200, 300]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };

    const userData = {
        labels: ['Rise B...', 'Jennif...', 'Pamel...'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [0, 10, 20]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };

    const filtersData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','Agost', 'September'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [90, 40, 38, 36, 25, 20, 11, 9, 3]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };
    
    
   const askedData = {
        labels: ['Rise B...', 'Jennif...', 'Pamel...'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [0, 5, 20]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };
    const recievedData = {
        labels: ['Rise B...', 'Jennif...', 'Pamel...'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [0, 1, 20]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };
    const talkData = {
        labels: ['Rise B...', 'Jennif...', 'Pamel...'],
        datasets: [
            {
                label: 'Yersterday',
                backgroundColor: '#437DF7',
                data: [0, 200, 400]
            },
            {
                label: 'Benchmark',
                backgroundColor: '#090A0A',
                data: []
            }
        ]
    };
    
    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        return{
            basicOptions
        }
    }
     
    const { basicOptions} = getLightTheme();
    const [value7, setValue7] = useState(57,161);
    
    const [selectedCities1, setSelectedCities1] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ]; 

   /*  const constructor (props){
        super(props);
        this.state = {isToggleOn:trus};
        this.handleClick = this.handleClick.bind(htis)
    }
    handleClick (){
        this.setState(prevState =>({
           isToggleOn
        }));
    } */
    const [value1, setValue1] = useState('Off');

    return(
        <div> 
            <div className="p-grid p-dir-col">    
                <div className="card">        
                    <h5 className='header' >The Pulse</h5>
                    <div class="p-col header">  
                    <SelectButton value={value2} options={paymentOptions} onChange={(e) => setValue2(e.value)} optionLabel="name" multiple/>
                    </div>   
                </div>
            </div> 
            
            <div className="p-grid">
                <div className='p-col'>
                    <div className="card">
                        <h5 className='header'>Calls</h5>
                        <Chart type="bar" data={basicData} options={basicOptions} />
                    </div>
                </div>
                <div className='p-col'>
                     <div className="card">
                        <h5 className='header'>Households Quoted</h5>
                        <Chart type="bar" data={userData} options={basicOptions} />
                    </div>
                </div>
            </div>
            <div className="p-grid">
                <div className='p-col'>
                     <div className="card">
                        <h5 className='header'>Referrals asked for</h5>
                        <Chart type="bar" data={askedData} options={basicOptions} />
                    </div>
                </div>
                <div className='p-col'>
                     <div className="card">
                        <h5 className='header'>Referrals Recieved</h5>
                        <Chart type="bar" data={recievedData} options={basicOptions} />
                    </div>
                </div>
            </div>
            <div className="p-grid">
                <div className='p-col-6'>
                     <div className="card">
                        <h5 className='header'>Talk Time (minutes)</h5>
                        <Chart type="bar" data={talkData} options={basicOptions} />
                    </div>
                </div>
            </div>
           {/*  <div className='p-grid p-justify-between diseño'>
                <div className='p-col-6'>
                    <h5 className='subtitle'>Hi Clinton, here´s your agency´s goal for today</h5>
                         
                </div>
                <div className='p-col-6'>
                    <Button label="Premium" className="p-button-rounded styles" />
                    <Button label="Policies" className="p-button-rounded p-button-secondary" />
                </div>
            </div> */}
            <div className="p-grid">
                <div className='p-col diseño'>
                    <h5 className='subtitle'>Hi Clinton, here´s your agency´s goal for today</h5>
                    <div className="card colour size">
                        <h6 className='titles'>STARTED TODAY AT</h6>
                        <h1>$125,323</h1>  
                    </div>
              
                   <div className="card colour size">
                        <h6 className='titles'>SHOULD BE AT</h6>
                        <h1>$183,182</h1>
                    </div>
               
                   <div className='card fondo size'>
                        <h1>$57,859</h1>
                        <h6 className='titles'>GOAL FOR TODAY</h6>              
                    </div>
                </div> 
                <div class='p-col still'>
                    <div className="p-col button">
                        <Button label="Premium" className="p-button-rounded styles" />
                        <Button label="Policies" className="p-button-rounded p-button-secondary" />
                    </div>
                        <div className="p-text-center">
                            <h5>STILL NEEDED</h5>
                            <Knob value={value7} strokeWidth={5} onChange={(e) => setValue7(e.value)}/>
                        </div>
                   <h6 className='header'>31% below goal of $310,000 </h6>
                </div> 
                <div className='p-col'>
                    <div className='p-grid p-justify-between'>
                        <div className='p-col-5'>
                            <h5 className='subtitle'>May stats</h5>     
                        </div>
                        <div className='p-col-7 buttons'>
                            <Button label="Premium" className="p-button-rounded styles" />
                            <Button label="Policies" className="p-button-rounded p-button-secondary" />
                        </div>
                    </div>
                    <div className='card fondo'>
                        <h6 className='header'>TRENDING FOR</h6>
                        <h1 className='titles header'>$213,266</h1>
                        <h6 className='header'>31% below goal of $310,000 </h6>
                    </div>
                    <div className='card fondo'> 
                        <div className='p-col'>                         
                            <h6>TRENDING FOR</h6>
                            <h1 className='titles'>15.8%</h1>
                     {/*        <div className='p-grid'> */}
                       {/*      <h1 className='titles'>$253,233</h1>
                            <h6>LAST MONTH</h6> */}
                       {/*      </div> */}
                        </div>                      
                    </div>
                    <div className='card fondo'> 
                        <div className='p-col'>                         
                            <h6>CURRENTLY AT</h6>
                            <h1 className='titles'>$126,021</h1>
                     {/*        <div className='p-grid'> */}
                       {/*      <h1 className='titles'>41%</h1>
                            <h6>OF GOAL</h6> */}
                       {/*      </div> */}
                        </div>                      
                    </div>                     
                </div>             
            </div>

            <div className='card selectCity'>
                <div className='p-grid'>
                    <div className='p-col-2'>
                        <MultiSelect value={selectedCities1} options={cities} onChange={(e) => setSelectedCities1(e.value)} optionLabel="name" placeholder="Written Business" maxSelectedLabels={3} />
                    </div>
                    <div class="p-col-5 dynamic ">  
                        <SelectButton value={value2} options={Options} onChange={(e) => setValue2(e.value)} optionLabel="name" multiple/>
                    </div> 
                    <div class="p-col-5 ">  
                        <SelectButton value={value2} options={categoryOptions} onChange={(e) => setValue2(e.value)} optionLabel="name" multiple/>
                    </div>             
                
                    <div className='p-col-4 /* p-dir-col */ spaceone'>
                        <h5>Filters</h5>
                    <div className="card colour space">
                            <h6 className='titles'>Time Frame</h6>
                            <MultiSelect value={selectedCities1} options={cities} onChange={(e) => setSelectedCities1(e.value)} optionLabel="name" placeholder="Apr 19-May18" maxSelectedLabels={3} />
                        </div>
                        <div className="card colour space">
                            <h6 className='titles'>Location & Agent:</h6>
                            <MultiSelect value={selectedCities1} options={cities} onChange={(e) => setSelectedCities1(e.value)} optionLabel="name" placeholder="All Location-All Agents" maxSelectedLabels={3} />
                        </div>
                        <div className="card colour space">
                            <h6 className='titles'>Carrier</h6>
                            <MultiSelect value={selectedCities1} options={cities} onChange={(e) => setSelectedCities1(e.value)} optionLabel="name" placeholder="All Carrier" maxSelectedLabels={3} />
                        </div>
                    </div>
                    <div className='p-col-8'>
                    <Chart type="bar" data={filtersData} options={basicOptions} />
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='p-grid'>
                    <div className='p-col-2'>
                        <h3>Activity Logger</h3>
                    </div>
                </div> 
            </div>
            <div className='card modelo'>
                <div className='p-grid diseños borde'>
                    <div className='p-col-4'> 
                    </div>
                    <div className='p-col-1 borde'>
                        <div className='fechas'>
                        <h2>MON</h2>
                        <h4>May</h4>
                        <h5>02</h5>
                        </div>
                    </div>
                    <div className='p-col-1 borde'>
                        <div className='fechas'>
                        <h2>TUE</h2>
                        <h4>May</h4>
                        <h5>03</h5>
                        </div>
                    </div>
                    <div className='p-col-1 borde'>
                        <div className='fechas'>
                        <h2>WED</h2>
                        <h4>May</h4>
                        <h5>04</h5>
                        </div>
                    </div>
                    <div className='p-col-1 borde'>
                        <div className='texto'>
                        <h2>THU</h2>
                        <h4>May</h4>
                        <h5>05</h5>
                        </div>
                    </div>
                    <div className='p-col-1 borde'>
                        <div className='fechas'>
                        <h2>FRI</h2>
                        <h4>May</h4>
                        <h5>06</h5>
                        </div>
                    </div>
                </div>
                <div className='p-grid borde'>
                    <div className='p-col-4'>
                        <h3>Calls</h3>
                    </div>
                    <div className='p-col-1 espacio'>
                     {/*    <button onClick={this.handleClick}>
                            {this.state.isToggleOn ? 'ON':'OFF'}
                        </button> */}
                         {/* <SelectButton value={value1} options={options} onChange={(e) => setValue1(e.value)} /> */}
                    </div>
                    <div className='p-col-1 espacio'>

                    </div>
                    <div className='p-col-1 espacio'>

                    </div>
                    <div className='p-col-1 espacio'>

                    </div>
                    <div className='p-col-1 espacio seis'>

                    </div>
                </div>

                <div className='p-grid borde'>
                    <div className='p-col-4'>
                        <h3>Households Quoted</h3>
                    </div>
                    <div className='p-col-1 espacio'>
                       
                    </div>
                    <div className='p-col-1 espacio'>
                       
                    </div>
                    <div className='p-col-1 espacio'>
                      
                    </div>
                    <div className='p-col-1 espacio'>
                
                    </div>
                    <div className='p-col-1 espacio seis'>

                    </div>
                </div>
                
                <div className='p-grid borde'>
                    <div className='p-col-4'>
                         <h3>Referrals asked for</h3>
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                       
                    </div>
                    <div className='p-col-1 espacio'>
                    </div>
                    <div className='p-col-1 espacio seis'>
                    </div>
                </div>
                <div className='p-grid borde'>
                    <div className='p-col-4'>
                        <h3>Referrals Recieved</h3>
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                       
                    </div>
                    <div className='p-col-1 espacio'>
                    </div>
                    <div className='p-col-1 espacio seis'>
                    </div>
                </div>
                <div className='p-grid borde'>
                    <div className='p-col-4'>
                        <h3>Talk Time (minutes)</h3>
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                        
                    </div>
                    <div className='p-col-1 espacio'>
                       
                    </div>
                    <div className='p-col-1 espacio'>
                    </div>
                    <div className='p-col-1 espacio seis'>
                    </div>
                </div>


            </div>


        </div>
    );

}

export default  ActivitysLogger;